/* eslint-disable @typescript-eslint/no-shadow */
import { Checkbox, Col, DatePicker, Form, Input, message, Row, Select, Switch } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { RcFile } from 'antd/lib/upload';

import clone from 'lodash/clone';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import { useCallback, ChangeEvent, useState, useEffect, ReactElement, useMemo } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { API_GET_ATTRIBUTE_OPTIONS } from 'constants/api/attribute.api';
import { API_GET_PRODUCTS, API_GET_PRODUCT_DETAIL } from 'constants/api/product.api';
import { API_GET_SIZES } from 'constants/api/size.api';
import { DateFormat } from 'constants/enum';
import { useFetchDrivingStyles } from 'hooks/useFetchDrivingStyles';
import { IAttributeGroup } from 'interfaces/attr-group.interface';
import { IAttributeOption } from 'interfaces/attr-option.interface';
import { IAttribute, IAttrAttribute, IAttributeValue } from 'interfaces/attribute.interface';
import { IImage } from 'interfaces/image.interface';
import { IProduct } from 'interfaces/product.interface';
import { IRiderModels } from 'interfaces/riderType';
import { setNewImageList } from 'redux/product/actions';
import { AppState } from 'redux/store';
import * as api from 'services/api.service';
import ColorPicker from 'ui/components/ColorPicker/ColorPicker';
import { CustomEditor } from 'ui/components/MaiNguyen/CustomEditor';
import { UploadFile } from 'ui/components/MaiNguyen/UploadFile';
import { uploadFile, generateSlug, showErrorMsg } from 'utils/utils';
import { ProductVehicleModel } from './ProductVehicleModel';

export interface AttributeGroupListInterface extends IAttributeGroup {
  attributeList: IAttribute[];
  subGroups?: AttributeGroupListInterface[];
}

const FieldByValidation = ({
  attribute,
  onChange,
  basicType = 'text',
}: {
  attribute: IAttrAttribute;
  onChange: (values: any) => void;
  basicType?: string;
}) => {
  switch (attribute.validation) {
    case 'decimal':
      const handleChangeValues = useCallback(
        (values: any) => {
          onChange(parseFloat(values.formattedValue.replaceAll(',', '')) || 0);
        },
        [onChange],
      );
      return (
        <NumberFormat
          className="ant-input"
          decimalSeparator="."
          thousandSeparator
          value={attribute.value}
          placeholder={attribute.defaultValue}
          onValueChange={handleChangeValues}
        />
      );
    case 'number':
      const handleChangeValue = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
          onChange(e.target.value);
        },
        [onChange],
      );
      return (
        <Input
          type="number"
          placeholder={attribute.defaultValue}
          value={attribute.value}
          onChange={handleChangeValue}
        />
      );
    case 'date':
      const handleChangeDate = useCallback(
        (_, dateString: string) => {
          onChange(dateString);
        },
        [onChange],
      );

      return (
        <DatePicker
          placeholder="Chọn ngày"
          format={DateFormat.clientSide}
          onChange={handleChangeDate}
          value={
            (attribute.value ? moment(attribute.value, DateFormat.clientSide) : undefined) as any
          }
        />
      );
    case 'date-range':
      const value = attribute.value
        ? attribute.value?.split(',')?.map((date) => moment(date, DateFormat.clientSideWithTime))
        : null;

      const handleChangeDateRange = useCallback(
        (_, dateRange: string[]) => {
          const date = dateRange;
          if (date[0] && date[1]) {
            onChange(dateRange.join(','));
          } else {
            onChange(null);
          }
        },
        [onChange],
      );

      return (
        <DatePicker.RangePicker
          placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
          showTime={{
            format: 'HH:mm:ss',
            defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
          }}
          format={DateFormat.clientSideWithTime}
          value={value}
          onChange={handleChangeDateRange}
        />
      );
    case 'boolean':
      const handleSwitch = useCallback(
        (checked: boolean) => {
          onChange(`${checked}`);
        },
        [onChange],
      );
      return <Switch checked={eval(attribute.value)} onChange={handleSwitch} />;
    case 'url':
      const handleChangeUrl = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
          onChange(generateSlug(e.target.value.trim()));
        },
        [onChange],
      );
      return (
        <Input
          value={attribute.value}
          placeholder={attribute.defaultValue}
          onChange={handleChangeUrl}
        />
      );
    case 'markdown':
      return (
        <CustomEditor
          mainContent={attribute.value}
          handleChangeMainContent={onChange}
          className="small-editor"
          editorId={`editor_${attribute.code}_${attribute.attributeId}`}
          metaContent
        />
      );
    default:
      const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          onChange(e.target.value);
        },
        [onChange],
      );
      return basicType === 'textarea' ? (
        <Input.TextArea
          placeholder={attribute.defaultValue}
          rows={5}
          value={attribute.value}
          onChange={handleChange}
        />
      ) : (
        <Input
          placeholder={attribute.defaultValue}
          value={attribute.value}
          onChange={handleChange}
        />
      );
  }
};

const FieldCol = ({
  attribute,
  col,
  children,
  hideDescription,
  initialValue,
}: {
  attribute: IAttrAttribute;
  col: number;
  children: ReactElement;
  hideDescription?: boolean;
  initialValue?: any;
}) => {
  return (
    <Col span={col}>
      <Form.Item initialValue={initialValue} label={attribute.adminLabel}>
        {children}
        {!hideDescription && <span>{attribute.description}</span>}
      </Form.Item>
    </Col>
  );
};

const FieldByType = ({
  attribute,
  isShippingGroup = false,
  useSwitcher = false,
  onChange,
  onSelectedRiderModel,
}: {
  attribute: IAttribute;
  isShippingGroup?: boolean;
  useSwitcher?: boolean;
  onChange: (values: any) => void;
  onSelectedRiderModel?: (riderModel: IRiderModels[]) => void;
}) => {
  const productsStore = (state: AppState) => state.productReducers;
  const { brands, categories, oses, statuses, productDetail, colors, subStatuses } =
    useSelector(productsStore);
  const dispatch = useDispatch();

  const { drivingStyles } = useFetchDrivingStyles(attribute.attributes.type);

  const drivingStylesOptions = useMemo(
    () =>
      drivingStyles.map((ds) => ({
        value: ds.id,
        label: ds.attributes.title,
      })),
    [drivingStyles],
  );

  const handleSelectList = useCallback(
    (selectedValue) => {
      onChange(selectedValue);
    },
    [onChange],
  );
  switch (attribute.attributes.type) {
    case 'text':
      return (
        <FieldCol col={12} attribute={attribute.attributes}>
          <FieldByValidation attribute={attribute.attributes} onChange={onChange} />
        </FieldCol>
      );
    case 'textarea':
      return (
        <FieldCol col={24} attribute={attribute.attributes}>
          <FieldByValidation
            attribute={attribute.attributes}
            onChange={onChange}
            basicType="textarea"
          />
        </FieldCol>
      );
    case 'boolean':
      const onChangeCheckBox = useCallback(
        (e: CheckboxChangeEvent) => {
          onChange(`${e.target.checked}`);
        },
        [onChange],
      );

      const onSwitchData = useCallback(
        (checked: boolean) => {
          onChange(`${checked}`);
        },
        [onChange],
      );

      const props = isShippingGroup
        ? ({
            style: { flexDirection: 'row-reverse' },
            labelCol: { span: 21 },
            wrapperCol: { span: 3 },
          } as any)
        : {};

      return (
        <Col span={isShippingGroup ? 24 : 12}>
          <Form.Item label={attribute.attributes.adminLabel} {...props}>
            {useSwitcher ? (
              <Switch checked={eval(attribute.attributes.value)} onChange={onSwitchData} />
            ) : (
              <Checkbox checked={eval(attribute.attributes.value)} onChange={onChangeCheckBox} />
            )}
          </Form.Item>
        </Col>
      );
    case 'tags':
      return (
        <FieldCol col={24} attribute={attribute}>
          <Form.Item label="Tags" name="keywords">
            <FieldByValidation
              attribute={attribute.attributes}
              onChange={onChange}
              basicType="textarea"
            />
          </Form.Item>
        </FieldCol>
      );
    case 'image':
      const [previewImage, setPreviewImage] = useState(attribute.attributes.value);

      const handleUploadImage = useCallback(
        (id: string) => (file: RcFile) => {
          let clonedProductDetail = clone(productDetail);

          const {
            id: productId,
            attributes: { attributeValues },
          } = clonedProductDetail;

          if (productId) {
            uploadFile(file, 'productAttributeValue', productId, '', '0', id).then(async (res) => {
              if (res) {
                clonedProductDetail = {
                  ...clonedProductDetail,
                  attributes: {
                    ...clonedProductDetail.attributes,
                    attributeValues: attributeValues.map((item) => {
                      if (item.attributeId.toString() === id) {
                        return {
                          ...item,
                          value: res?.resize,
                        };
                      }
                      return item;
                    }),
                  },
                };

                await api
                  .put({
                    endpoint: API_GET_PRODUCT_DETAIL.replace('{id}', productId),
                    data: clonedProductDetail,
                  })
                  .then((resp) => {
                    const imageAttrValues = resp?.data.attributes.attributeValues.find(
                      (item: IAttributeValue) => item.attributeId === parseInt(id),
                    );

                    if (imageAttrValues) {
                      setPreviewImage(imageAttrValues.value);
                      onChange(imageAttrValues.value);
                      message.success('Upload image(s) success!', 3);
                    }
                  })
                  .catch((err) => {
                    showErrorMsg(err);
                  });
              }
            });
          }
        },
        [onChange],
      );

      const handleChangeImages = useCallback(
        (newList: IImage[]) => {
          onChange(newList);
          dispatch(setNewImageList(newList));
        },
        [onChange, dispatch],
      );

      return (
        <Col span={24}>
          <UploadFile
            previewImage={previewImage}
            handleUploadMultipleImages={handleUploadImage(attribute.id)}
            handleChangeImagesPosition={handleChangeImages}
            handleSelectMainImage={handleChangeImages}
            handleSelectSecondImage={handleChangeImages}
            multiple
            description={attribute.attributes.description}
            uploadText="Chọn hoặc kéo 1 hay nhiều hình ảnh để upload"
            imageLifestyle
            isSelectLifestyleImg
          />
        </Col>
      );
    case 'file':
      const [previewPdfFiles, setPreviewPdfFiles] = useState(attribute.attributes.value);

      const handleUploadPdfFiles = useCallback(
        (id: string) => (file: RcFile) => {
          const clonedProductDetail = clone(productDetail);

          const { id: productId } = clonedProductDetail;

          if (productId) {
            uploadFile(file, 'productfile', productId, '', '0', id).then(async (res) => {
              if (res) {
                await api
                  .put({
                    endpoint: API_GET_PRODUCT_DETAIL.replace('{id}', productId),
                    data: clonedProductDetail,
                  })
                  .then((resp) => {
                    const imageAttrValues = resp?.data.attributes.attributeValues.find(
                      (item: IAttributeValue) => item.attributeId === parseInt(id),
                    );

                    if (imageAttrValues) {
                      setPreviewPdfFiles(imageAttrValues.value);
                      onChange(imageAttrValues.value);
                      message.success('Upload PDF file(s) success!', 3);
                    }
                  })
                  .catch((err) => {
                    showErrorMsg(err);
                  });
              }
            });
          }
        },
        [onChange],
      );

      return (
        <FieldCol
          col={24}
          attribute={attribute.attributes}
          hideDescription={attribute.id === '139'}
        >
          <UploadFile
            previewFile={previewPdfFiles}
            handleUploadMultipleImages={handleUploadPdfFiles(attribute.id)}
            fileAccept=".pdf"
            fileType="application/pdf"
            multiple
            description={attribute.attributes.description}
            uploadText="Chọn hoặc kéo 1 hay nhiều file PDF để upload"
          />
        </FieldCol>
      );
    case 'select':
      const [selectData, setSelectData] = useState([]);

      useEffect(() => {
        api
          .get({ endpoint: API_GET_ATTRIBUTE_OPTIONS.replace('{id}', attribute.id) })
          .then((res) => {
            setSelectData(res?.data);
          })
          .catch((err) => {
            showErrorMsg(err);
          });
      }, []);

      return (
        <FieldCol col={12} attribute={attribute.attributes}>
          <Select
            className="ant-select-lg"
            value={attribute.attributes.value}
            showSearch
            optionFilterProp="children"
            onChange={handleSelectList}
          >
            {selectData.map((slData) => (
              <Select.Option key={slData.id} value={slData.id}>
                {slData.attributes.name}
              </Select.Option>
            ))}
          </Select>
        </FieldCol>
      );
    case 'multiple_select':
      const [selectDataOption, setSelectDataOption] = useState<IAttributeOption[]>([]);
      const [optionsValues, setOptionsValues] = useState([]);

      useEffect(() => {
        api
          .get({ endpoint: API_GET_ATTRIBUTE_OPTIONS.replace('{id}', attribute.id) })
          .then((res) => {
            setSelectDataOption(res?.data);
          })
          .catch((err) => {
            showErrorMsg(err);
          });
      }, []);

      useEffect(() => {
        try {
          setOptionsValues(JSON.parse(attribute.attributes.value));
        } catch (error) {
          setOptionsValues([]);
        }
      }, []);

      const onSelectOptions = useCallback(
        (values) => {
          setOptionsValues(values);
          onChange(JSON.stringify(values));
        },
        [onChange, selectDataOption],
      );

      return (
        <FieldCol col={24} attribute={attribute.attributes}>
          <Select
            size="large"
            allowClear
            mode="multiple"
            showSearch
            value={optionsValues}
            optionFilterProp="children"
            onChange={onSelectOptions}
            placeholder="Chọn options"
          >
            {selectDataOption.map((item) => (
              <Select.Option key={item.id} value={parseInt(item.id)}>
                {item.attributes.name}
              </Select.Option>
            ))}
          </Select>
        </FieldCol>
      );
    case 'brand':
      return (
        <FieldCol col={12} attribute={attribute.attributes}>
          <Select
            className="ant-select-lg"
            showSearch
            optionFilterProp="children"
            value={attribute.attributes.value}
            onChange={handleSelectList}
          >
            {brands.map((brand) => (
              <Select.Option key={brand.id} value={brand.id}>
                {brand.attributes.name}
              </Select.Option>
            ))}
          </Select>
        </FieldCol>
      );
    case 'os':
      return (
        <FieldCol col={12} attribute={attribute.attributes}>
          <Select
            className="ant-select-lg"
            showSearch
            optionFilterProp="children"
            value={attribute.attributes.value}
            onChange={handleSelectList}
          >
            {oses.map((os) => (
              <Select.Option key={os.id} value={os.id}>
                {os.attributes.name}
              </Select.Option>
            ))}
          </Select>
        </FieldCol>
      );
    case 'category':
      return (
        <FieldCol col={12} attribute={attribute.attributes}>
          <Select
            className="ant-select-lg"
            showSearch
            optionFilterProp="children"
            value={attribute.attributes.value}
            onChange={handleSelectList}
          >
            {categories.map((category) => (
              <Select.Option key={category.id} value={category.id}>
                {category.attributes.name}
              </Select.Option>
            ))}
          </Select>
        </FieldCol>
      );
    case 'status':
      return (
        <FieldCol col={12} attribute={attribute.attributes}>
          <Select
            className="ant-select-lg"
            value={attribute.attributes.value}
            onChange={handleSelectList}
          >
            {statuses.map((status) => (
              <Select.Option key={status.id} value={status.id}>
                {status.attributes.name}
              </Select.Option>
            ))}
          </Select>
        </FieldCol>
      );
    case 'sub status':
      return (
        <FieldCol col={12} attribute={attribute.attributes}>
          <Select
            className="ant-select-lg"
            value={attribute.attributes.value}
            onChange={handleSelectList}
          >
            {subStatuses.map((status) => (
              <Select.Option key={status.id} value={status.id}>
                {status.attributes.name}
              </Select.Option>
            ))}
          </Select>
        </FieldCol>
      );
    case 'color':
      return (
        <FieldCol col={12} attribute={attribute.attributes}>
          <Select
            className="ant-select-lg"
            value={attribute.attributes.value}
            showSearch
            optionFilterProp="children"
            onChange={handleSelectList}
          >
            {colors.map((color) => (
              <Select.Option key={color.id} value={color.id}>
                {color.attributes.name}
              </Select.Option>
            ))}
          </Select>
        </FieldCol>
      );
    case 'color_wheel':
      const handleChangeColor = useCallback(
        (selectColors) => {
          onChange(selectColors[0].color);
        },
        [onChange],
      );

      return (
        <FieldCol col={12} attribute={attribute.attributes}>
          <ColorPicker
            className="color-picker w-50"
            firstColor={attribute.attributes.value}
            onColorChange={handleChangeColor}
          />
        </FieldCol>
      );
    case 'product':
      const [products, setProducts] = useState<IProduct[]>([]);
      const [loading, setLoading] = useState(true);

      useEffect(() => {
        api
          .get({ endpoint: `${API_GET_PRODUCTS}?perPage=all` })
          .then((res) => {
            setProducts(res?.data?.filter((item: IProduct) => item.id !== productDetail.id));
            setLoading(false);
          })
          .catch((err) => {
            showErrorMsg(err);
            setLoading(false);
          });
      }, []);

      const handleSelectProducts = useCallback(
        (productIds: string[]) => {
          if (isEmpty(productIds)) {
            onChange && onChange('');
          } else {
            onChange && onChange(productIds.join(','));
          }
        },
        [onChange],
      );

      return (
        <FieldCol col={24} attribute={attribute.attributes}>
          <Select
            size="large"
            allowClear
            mode="multiple"
            showSearch
            value={attribute.attributes.value ? attribute.attributes.value?.split(',') : []}
            optionFilterProp="children"
            onChange={handleSelectProducts}
            loading={loading}
            disabled={loading}
            placeholder="Chọn sản phẩm"
          >
            {products
              .filter((item) => item.id !== productDetail.id)
              .map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.attributes.name}
                </Select.Option>
              ))}
          </Select>
        </FieldCol>
      );
    case 'size_group':
      const [sizeGroup, setSizeGroup] = useState([]);
      const sizeGroupFilter = `&sizeGroupId=${attribute.attributes.defaultValue}`;

      useEffect(() => {
        api
          .get({ endpoint: `${API_GET_SIZES}?perPage=all${sizeGroupFilter}` })
          .then((res) => {
            setSizeGroup(res?.data);
          })
          .catch((err) => {
            showErrorMsg(err);
          });
      }, []);

      return (
        <FieldCol col={12} attribute={attribute.attributes}>
          <Select
            className="ant-select-lg"
            value={attribute.attributes.value}
            showSearch
            optionFilterProp="children"
            onChange={handleSelectList}
          >
            {sizeGroup.map((slData) => (
              <Select.Option key={slData.id} value={slData.id}>
                {slData.attributes.title}
              </Select.Option>
            ))}
          </Select>
        </FieldCol>
      );
    case 'model_bike':
      const handleSelectedRiderModel = useCallback(
        (productIds) => {
          onSelectedRiderModel(productIds);
        },
        [onSelectedRiderModel],
      );

      return (
        <FieldCol col={24} attribute={attribute.attributes}>
          <ProductVehicleModel handleUpdateSelectedRiderModel={handleSelectedRiderModel} />
        </FieldCol>
      );

    case 'ride_style':
      const attributeValue = attribute.attributes?.value;
      return (
        <FieldCol col={12} attribute={attribute.attributes}>
          <Select
            showSearch
            className="ant-select-lg"
            dropdownClassName="riding-styles-dropdown"
            mode="multiple"
            allowClear
            placeholder="Chọn phong cách lái"
            defaultValue={attributeValue ? JSON.parse(attributeValue) : []}
            onChange={(values: any) =>
              handleSelectList(values?.length ? JSON.stringify(values) : '')
            }
            options={drivingStylesOptions}
          />
        </FieldCol>
      );
    default:
      return <></>;
  }
};

export const FieldList = ({
  fields,
  onChange,
  isShippingGroup = false,
  useSwitcher = false,
  onSelectedRiderModel,
}: {
  fields: IAttribute[];
  isShippingGroup?: boolean;
  useSwitcher?: boolean;
  onChange: (id: string, values: any) => void;
  onSelectedRiderModel?: (riderModel: IRiderModels[]) => void;
}) => {
  const handleSelectedRiderModel = useCallback(
    (productIds) => {
      onSelectedRiderModel(productIds);
    },
    [onSelectedRiderModel],
  );

  return (
    <Row gutter={16}>
      {uniqBy(fields, 'id')?.map((item) => {
        return (
          <FieldByType
            isShippingGroup={isShippingGroup}
            key={item.id}
            attribute={item}
            onChange={(values) => onChange(item.id, values)}
            onSelectedRiderModel={handleSelectedRiderModel}
            useSwitcher={useSwitcher}
          />
        );
      })}
    </Row>
  );
};
